import { FragmentType, getFragmentData } from "~/__generated__"
import { modalityDisplay, sexDisplay } from "~/common/study"
import { StudyScreenContent_StudyFragment } from "~/components/StudyScreenContent"
import { TD, TH, Table } from "~/ui/DetailTable"

const StudyDetailsTable = ({
  data,
}: {
  data: FragmentType<typeof StudyScreenContent_StudyFragment>
}) => {
  const { modality, patientAge, sex, seriesDescriptions } = getFragmentData(
    StudyScreenContent_StudyFragment,
    data
  )

  return (
    <Table>
      <thead>
        <tr>
          <TH>Details</TH>
          <TH>Values</TH>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TD>Modality</TD>
          <TD>{modalityDisplay(modality)}</TD>
        </tr>
        <tr>
          <TD>Patient Age</TD>
          <TD>{patientAge}</TD>
        </tr>
        <tr>
          <TD>Sex</TD>
          <TD>{sexDisplay(sex)}</TD>
        </tr>
        <tr>
          <TD>Series Descriptions</TD>
          <TD>
            {seriesDescriptions?.map((description) => <div>{description}</div>)}
          </TD>
        </tr>
      </tbody>
    </Table>
  )
}

export default StudyDetailsTable
